.app {
  background-image: linear-gradient(45deg, #d1d1d1 0%, #c9c7c5 90%);
  background-attachment: fixed;
}

.tile {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  color: #535353;
}

.content {
  color: #535353;
  max-width: 400px;
}

.link {
  color: #535353;
}

.link:hover {
  color: white;
}

